<template>
    <div class="container">

      <!-- <div id="loader" v-if="loading"></div> -->

      <div v-if="!loading && responseText" class="center">
        <img class="image"
             :src="require('@/assets/images/partying.png')" />
        <h2>Thank you!</h2>
        <p class="description">{{responseText}}</p>

        <base-button v-if="user" class="cta-button"
                     color="primary"
                     @click="redirectToHome()">Create a Monitor -></base-button>

        <base-button v-else class="cta-button"
                     color="primary"
                     @click="redirectToLogin()">Log In -></base-button>
      </div>

      <div v-if="!responseText && error" class="center">
        <img class="image"
             :src="require('@/assets/images/grimacing.png')" />

        <h2>Something went wrong...</h2>
        <p class="description">{{error}}</p>

        <base-button v-if="user" class="cta-button"
                     color="primary"
                     @click="redirectToHome()">Go to Homepage</base-button>

        <base-button v-else class="cta-button"
                     color="primary"
                     @click="redirectToLogin()">Log In -></base-button>
      </div>

    </div>
</template>

<script>
import auth from '../services/auth'

export default {
  name: 'email_verify',

  data () {
    return {
      loading: false,
      error: false,
      email: null,
      hash: null,
      responseText: false
    }
  },

  mounted () {
    this.getEmailFromUrl()
    this.getHashFromUrl()
    this.verifyEmail()
  },

  methods: {
    getEmailFromUrl () {
      this.email = this.$route.query.email.replace(' ', '+')
    },

    getHashFromUrl () {
      this.hash = this.$route.query.hash
    },

    async verifyEmail () {
      const data = { email: this.email, hash: this.hash }
      this.responseText = await auth.verifyEmail(data)
      if (this.responseText === false) {
        this.error = 'We could not verify your email.'
      }

      this.loading = false
    },

    redirectToLogin () {
      window.location.href = '/login'
    },

    redirectToHome () {
      window.location.href = '/'
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style scoped>
    /* #loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 120px;
    height: 120px;
    margin: -76px 0 0 -76px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #7e3ea8;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  } */

  html, body {
    background: map-get($colors, background);
  }

  .image {
    width: 54px;
    opacity: 1;
    margin: 100px 0 15px;
    animation-delay: 0.2s;
  }

  h2 {
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -.024em;
    margin-top: 0;
  }

  .description {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  .cta-button {
    height: 40px;
    margin: 0 auto;
    font-size: 16px;
  }

  /* Add animation to "page content" */
/*   .animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s;
    text-align: center;
  }

  @-webkit-keyframes animatebottom {
    from { bottom:-100px; opacity:0 }
    to { bottom:0px; opacity:1 }
  }

  @keyframes animatebottom {
    from{ bottom:-100px; opacity:0 }
    to{ bottom:0; opacity:1 }
  } */

  .center {
    text-align: center;
  }

/*   .loginLink {
    color: #7e3ea8
  } */
</style>
